@import "../global.scss";

.home-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: #707070;
  background: #f5f5f5;
  .title-list{
    display: flex;
    flex-direction: column;
    &>h1{
      margin: 0;
      font-size: 4em;
      font-weight: 600;
      @media screen and (max-width: 768px) {
        font-size: 2em;
      }
    }
  }
  .hero-wrapper {
    width: 100%;
    height: calc(100vh);
    @media screen and (max-width: 768px) {
      height: 100vh;
    }
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: url("./img/hero.jpg");
    background-position: center top;
    background-repeat: no-repeat;
    background-size: cover;
    color: #fff;

    h2 {
      color: #fff;
      font-size: 1.5em;
      margin-top: 32px;
    }
  }
  button {
    width: 369px;
    height: 80px;
    @media screen and (max-width: 768px) {
      width: 100%;
      height: 42px;
    }

    border: 2px solid #078077;
    border-radius: 8px;
    background-color: #ffffff;
    color: #078077;
    font-family: "Encode Sans Bold";
    @include m-breakpoints;
    cursor: pointer;
    transition: background-color 0.3s ease-out, color 0.3s ease-out;

    &:hover {
      background-color: #078077;
      color: #fff;
    }
  }
  .main-pages {
    width: 100%;
    display: flex;
    @media screen and (max-width: 768px) {
      flex-direction: column;
    }
    gap: 2px;
    margin-top: 2px;
    text-align: left;
    .mp {
      width: 50%;
      background: #ffffff;
      padding: 80px 140px;
      @media screen and (max-width: 768px) {
        padding: 42px;
        width: calc(100% - 84px);
      }
      h2 {
        color: #0d3c51;
        font-weight: 600;
        font-size: 42px;
        font-family: "Encode Sans Semi Bold";
        @media screen and (max-width: 768px) {
          margin-bottom: 16px;
        }
      }
      h3 {
        color: #0d3c51;
        font-weight: 500;
        font-family: "Poppins";
        font-size: 18px;
        font-weight: 500;
        margin: 16px 0 56px 0;
        @media screen and (max-width: 768px) {
          margin: 0 0 24px 0;
        }
      }
    }
  }
  //partners
  .partners-header {
    margin-top: 69px;
  }
  .carousel-control {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    .arrowLeft,
    .arrowRight {
      margin-top: 69px;
      cursor: pointer;
      &.mobileOnly {
        padding-top: 69px;
        @media screen and (min-width: 768px) {
          display: none;
        }
        &.disabled {
          color: #c1c1c1;
        }
      }
      &.desktopOnly {
        @media screen and (max-width: 768px) {
          display: none;
        }
      }
    }
    .arrowLeft {
      &.mobileOnly {
        padding-left: 21px;
      }
    }
    .arrowRight {
      &.mobileOnly {
        padding-right: 21px;
      }
    }
    .carousel {
      width: calc(100% - 280px);
      @media screen and (max-width: 768px) {
        width: 100%;
      }
      overflow: hidden;

      &.mobileOnly {
        @media screen and (min-width: 768px) {
          display: none;
        }
      }
      &.desktopOnly {
        @media screen and (max-width: 768px) {
          display: none;
        }
      }

      .logo-slides {
        position: relative;
        display: flex;
        transition: right 0.5s;
        width: 3360px;
        @media screen and (max-width: 768px) {
          width: 700%;
        }
        &.pos0 {
          right: 0;
        }
        &.pos1 {
          right: 420px;
          @media screen and (max-width: 768px) {
            right: 100%;
          }
        }
        &.pos2 {
          right: 840px;
          @media screen and (max-width: 768px) {
            right: 200%;
          }
        }
        &.pos3 {
          right: 1260px;
          @media screen and (max-width: 768px) {
            right: 300%;
          }
        }
        &.pos4 {
          right: 1680px;
          @media screen and (max-width: 768px) {
            right: 400%;
          }
        }
        &.pos5 {
          right: 500%;
        }
        &.pos6 {
          right: 600%;
        }

        .logo-slide {
          height: fit-content;
          width: 340px;
          padding: 0 40px;
          @media screen and (max-width: 768px) {
            height: 420px;
            width: 100vw;
            padding: 0;
          }

          display: flex;
          flex-direction: column;
          gap: 21px;
          align-items: center;

          .logo {
            width: 169px;
            height: 30px;
            &.high {
              height: 60px;
            }

            &.abacus {
              background: url("./img/abacus.svg");
              background-size: contain;
              background-repeat: no-repeat;
              background-position: center;
            }
            &.allme {
              background: url("./img/allme.png");
              background-size: contain;
              background-repeat: no-repeat;
              background-position: center;
            }
            &.amo {
              background: url("./img/amo.png");
              background-size: contain;
              background-repeat: no-repeat;
              background-position: center;
              transform: scale(1.5) translateY(12px);
            }
            &.blockmason {
              background: url("./img/blockmason.png");
              background-size: contain;
              background-repeat: no-repeat;
              background-position: center;
            }
            &.carry {
              background: url("./img/carry.png");
              background-size: contain;
              background-repeat: no-repeat;
              background-position: center;
            }
            &.coinbase {
              background: url("./img/coinbase.png");
              background-size: contain;
              background-repeat: no-repeat;
              background-position: center;
            }
            &.fantom {
              background: url("./img/fantom.png");
              background-size: contain;
              background-repeat: no-repeat;
              background-position: center;
            }
            &.fuzex {
              background: url("./img/fuzex.png");
              background-size: contain;
              background-repeat: no-repeat;
              background-position: center;
              transform: scale(1.5);
            }
            &.gifto {
              background: url("./img/gifto.png");
              background-size: contain;
              background-repeat: no-repeat;
              background-position: center;
            }
            &.hold {
              background: url("./img/hold.png");
              background-size: contain;
              background-repeat: no-repeat;
              background-position: center;
            }
            &.iht {
              background: url("./img/iht.png");
              background-size: contain;
              background-repeat: no-repeat;
              background-position: center;
            }
            &.link {
              background: url("./img/link.png");
              background-size: contain;
              background-repeat: no-repeat;
              background-position: center;
            }
            &.lylaw {
              background: url("./img/lylaw.svg");
              background-size: contain;
              background-repeat: no-repeat;
              background-position: center;
              height: 42px;
            }
            &.magnachain {
              background: url("./img/magnachain.png");
              background-size: contain;
              background-repeat: no-repeat;
              background-position: center;
              height: 42px;
            }
            &.plair {
              background: url("./img/plair.png");
              background-size: contain;
              background-repeat: no-repeat;
              background-position: center;
            }
            &.ripple {
              background: url("./img/ripple.png");
              background-size: contain;
              background-repeat: no-repeat;
              background-position: center;
            }
            &.singularity {
              background: url("./img/singularity.png");
              background-size: contain;
              background-repeat: no-repeat;
              background-position: center;
              height: 42px;
            }
            &.stellar {
              background: url("./img/stellar.png");
              background-size: contain;
              background-repeat: no-repeat;
              background-position: center;
            }
            &.zeex {
              background: url("./img/zeex.png");
              background-size: contain;
              background-repeat: no-repeat;
              background-position: center;
              height: 42px;
            }
            &.zilliqa {
              background: url("./img/zilliqa.png");
              background-size: contain;
              background-repeat: no-repeat;
              background-position: center;
              height: 42px;
            }
            &.sunnysideup {
              background: url("../team/img/sunnysideup.png");
              background-size: contain;
              background-repeat: no-repeat;
              background-position: center center;
            }
            &.sunent {
              background: url("../team/img/sunent.png");
              background-size: contain;
              background-repeat: no-repeat;
              background-position: center center;
            }
            &.allbit {
              background-color: #fff;
              background-image: url("../team/img/allbit.png");
              background-size: contain;
              background-repeat: no-repeat;
              background-position: center center;
              border-radius: 8px;
            }
            &.bitgosu {
              background-color: #fff;
              background-image: url("../team/img/bitgosu.png");
              background-size: contain;
              background-repeat: no-repeat;
              background-position: center center;
              border-radius: 8px;
            }
            &.coinhako {
              background-color: #fff;
              background-image: url("../team/img/coinhako.png");
              background-size: contain;
              background-repeat: no-repeat;
              background-position: center center;
              border-radius: 8px;
            }
            &.coinsuper {
              background: url("../team/img/coinsuper.png");
              background-size: contain;
              background-repeat: no-repeat;
              background-position: center center;
            }
            &.coinsnetwork {
              background: url("../team/img/coinsnetwork.png");
              background-size: contain;
              background-repeat: no-repeat;
              background-position: center center;
            }
            &.dna {
              background: url("../team/img/dna.png");
              background-size: contain;
              background-repeat: no-repeat;
              background-position: center center;
            }
            &.eventprotocol {
              background: url("../team/img/eventprotocol.png");
              background-size: contain;
              background-repeat: no-repeat;
              background-position: center center;
            }
            &.fintnews {
              background: url("../team/img/fintnews.png");
              background-size: contain;
              background-repeat: no-repeat;
              background-position: center center;
            }
            &.foundationx {
              background: url("../team/img/foundationx.png");
              background-size: contain;
              background-repeat: no-repeat;
              background-position: center center;
            }
            &.gb {
              background: url("../team/img/gb.png");
              background-size: contain;
              background-repeat: no-repeat;
              background-position: center center;
            }
            &.hikari {
              background: url("../team/img/hikari.png");
              background-size: contain;
              background-repeat: no-repeat;
              background-position: center center;
            }
            &.lylaw {
              background: url("../team/img/lylaw.png");
              background-size: contain;
              background-repeat: no-repeat;
              background-position: center center;
            }
            &.wxygroup {
              background: url("../team/img/wxygroup.png");
              background-size: contain;
              background-repeat: no-repeat;
              background-position: center center;
            }
            &.nexo {
              background: url("./img/gsnexo.png");
              background-size: contain;
              background-repeat: no-repeat;
              background-position: center center;
            }
            &.orbs {
              background: url("./img/orbs.png");
              background-size: contain;
              background-repeat: no-repeat;
              background-position: center center;
            }
            &.zklend {
              background: url("./img/zklendgs.png");
              background-size: contain;
              background-repeat: no-repeat;
              background-position: center center;
            }
            &.dehorizon {
              background: url("./img/dehorizon.jpg");
              background-size: contain;
              background-repeat: no-repeat;
              background-position: center center;
            }
            &.the9 {
              background: url("./img/the9.png");
              background-size: contain;
              background-repeat: no-repeat;
              background-position: center center;
            }
            &.eighthours {
              background: url("./img/8hours.png");
              background-size: contain;
              background-repeat: no-repeat;
              background-position: center center;
            }
            &.treelion {
              background: url("./img/treelion.png");
              background-size: contain;
              background-repeat: no-repeat;
              background-position: center center;
            }
            &.axion {
              background: url("./img/axion.png");
              background-size: contain;
              background-repeat: no-repeat;
              background-position: center center;
              height: 42px;
            }
            &.cachet {
              background: url("./img/cachet.png");
              background-size: contain;
              background-repeat: no-repeat;
              background-position: center center;
            }
            &.dcentral {
              background: url("./img/dcentral.png");
              background-size: contain;
              background-repeat: no-repeat;
              background-position: center center;
              height: 42px;
            }
            &.lendingblock {
              background: url("./img/lendingblock.png");
              background-size: contain;
              background-repeat: no-repeat;
              background-position: center center;
            }
            &.nexmoon {
              background: url("./img/nexmoon.png");
              background-size: contain;
              background-repeat: no-repeat;
              background-position: center center;
              height: 38px;
            }
            &.coinseer {
              background: url("./img/coinseer.png");
              background-size: contain;
              background-repeat: no-repeat;
              background-position: center center;
            }
            &.pundix {
              background: url("./img/pundix.png");
              background-size: contain;
              background-repeat: no-repeat;
              background-position: center center;
            }
            &.playtable {
              background: url("./img/playtable.png");
              background-size: contain;
              background-repeat: no-repeat;
              background-position: center center;
            }
          }
        }
      }
    }
  }
  .dots {
    margin: 42px 0 69px 0;
    @media screen and (max-width: 768px) {
      margin-top: -69px; //i know negative margin is a no-no, but fuck it.
    }
    display: flex;
    gap: 10px;
    &.mobileOnly {
      @media screen and (min-width: 768px) {
        display: none;
      }
    }
    &.desktopOnly {
      @media screen and (max-width: 768px) {
        display: none;
      }
    }

    .dot {
      width: 10px;
      height: 10px;
      border-radius: 10px;
      margin-top: 1.5px;
      border: 1px solid #0d3c51;
      cursor: pointer;

      .inner-dot {
        width: 6px;
        height: 6px;
        background: #ffffff;
        margin-top: 2px;
        margin-left: 2px;
        border-radius: 6px;
      }

      &.selected {
        margin-top: 0;
        border: 3px solid #0d3c51;

        .inner-dot {
          background: #0d3c51;
        }
      }
    }
  }
  .cards-wrapper {
    padding-top: 160px;
    @media screen and (max-width: 768px) {
      padding-top: 69px;
    }
    width: 100%;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    h2 {
      color: #0d3c51;
    }
    h3 {
      margin-bottom: 16px;
    }
    .cards {
      display: flex;
      margin: 69px 0 21px 0;
      @media only screen and (max-width: 1920px) {
        width: calc(100% - 280px);
        padding: 0 140px;
      }
      @media only screen and (max-width: 1600px) {
        width: calc(100% - 142px);
        padding: 0 71px;
      }
      @media only screen and (max-width: 768px) {
        padding: 0;
        width: 100%;
        margin: 42px 0 21px 0;
      }
      @media screen and (max-width: 768px) {
        flex-direction: column;
      }
      gap: 16px;

      .card {
        width: 50%;
        height: 420px;
        background: #0d2837;
        color: #ffffff;
        text-align: left;
        padding: 80px;
        @media only screen and (max-width: 768px) {
          margin-left: 14px;
          padding: 38px;
          width: calc(100% - 115px);
          height: fit-content;
        }

        .card-img {
          width: 150px;
          height: 150px;
          &.nimbleness {
            background: url("./img/nimbleness.jpg");
            background-size: contain;
            background-repeat: no-repeat;
          }
          &.experience {
            background: url("./img/experience.jpg");
            background-size: contain;
            background-repeat: no-repeat;
          }
          &.expertise {
            background: url("./img/expertise.jpg");
            background-size: contain;
            background-repeat: no-repeat;
          }
        }
        h2 {
          color: #fff;
        }
        h3 {
          color: #fff;
          font-family: "Encode Sans Bold";
          font-weight: 100;
          margin: 40px 0 16px 0;
          @include lg-breakpoints;
        }
      }
    }
  }
  .whiteback.dark {
    @media screen and (max-width: 768px) {
      width: calc(100% - 42px);
      border: 20px solid #fff;
      padding-top: 42px;
    }
    h3 {
      margin-bottom: 16px;
    }
    h2 {
      color: #0d3c51;
    }
    .read-more {
      color: #078077;
      width: 100%;
      margin: 42px 0;
    }
    a {
      text-decoration-color: #078077;
    }
  }
  .stats-wrapper {
    padding-top: 160px;
    @media screen and (max-width: 768px) {
      padding-top: 42px;

      h3 {
        margin-bottom: 42px;
      }
    }
  }
  .map-wrapper {
    display: flex;
    margin-top: 169px;
    gap: 69px;
    width: 100%;
    @media screen and (max-width: 768px) {
      flex-direction: column;
      margin-top: 69px;
      gap: 0;
    }

    .map {
      width: calc(66% - 69px);
      height: 420px;
      background: url("./img/map.png");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center right;
      @media screen and (max-width: 768px) {
        width: 100%;
        height: 200px;
        background-position: center center;
      }
    }
    .maptext {
      width: 33%;
      display: flex;
      flex-direction: column;
      text-align: left;
      padding: 42px 69px;
      @media screen and (max-width: 768px) {
        width: calc(100% - 42px);
        padding: 16px 21px;
      }

      h1 {
        @include lg-breakpoints;
        margin-top: 69px;
        @media screen and (max-width: 768px) {
          @include x-lg-breakpoints;
          margin-top: 16px;
        }
        color: #0d3c51;
        font-weight: 600;
      }
      p {
        color: #0d3c51;
        font-weight: 500;
      }
    }
  }
  .speak {
    width: 420px;
    margin: 120px 0;
    @media screen and (max-width: 768px) {
      width: calc(100% - 42px);
      margin: 69px 0;
    }
  }
}
