@import "../global.scss";
.header-wrapper {
  position: fixed;
  width: 100%;
  height: 100px;
  .token-advisory{
    color: white;
    font-weight: 600;
    cursor: pointer;
    font-family: "Encode Sans Semi Bold";
  }
  @media screen and (max-width: 768px) {
    height: 69px;
  }
  background: #0d3c51;
  display: flex;
  justify-content: space-between;
  z-index: 1;
  box-shadow: 0px 3px 6px #00000029;

  .mask {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    transition: 0.125s opacity ease-out;
    pointer-events: none;
    z-index: 99;

    &.visible {
      opacity: 0.66;
      background-color: #000000;
      pointer-events: all;
    }
  }

  .modal {
    position: fixed;
    top: 69px;
    width: calc(100% - 267px);
    height: fit-content;
    margin: 69px;
    padding: 64px;
    @media screen and (max-width: 1024px) {
      margin: 10.5px;
      padding: 10.5px;
      width: calc(100% - 42px);
      height: calc(100vh - 42px);
      top: 10.5px;
    }
    border-radius: 8px;
    background: #0d2837;
    color: #ffffff;
    z-index: 100;

    .mobileOnly {
      display: none;
      @media screen and (max-width: 768px) {
        display: inline;
      }
    }

    .ex {
      position: absolute;
      top: 16px;
      right: 16px;
      color: #fff;
      cursor: pointer;
      @include lg-breakpoints;
    }

    h1 {
      @include lg-breakpoints;
      font-weight: 600;
      color: #ffffff;
      font-family: "Encode Sans Bold";
    }
    .modal-title {
      color: #ffffff;
      font-weight: 900;
      margin-bottom: 14px;
      @include s-breakpoints;
      font-family: "Poppins";
    }
    section {
      display: flex;
      flex-direction: column;
    }

    &.terms-of-service {
      h3 {
        font-weight: 600;
        color: #ffffff;
        margin-top: 8px;
        margin-bottom: 38px;
      }

      .investors {
        display: flex;
        gap: 24px;
        width: 100%;
        margin-bottom: 38px;
        @media screen and (max-width: 768px) {
          flex-direction: column;
          gap: 16px;
          margin-bottom: 16px;
        }

        .investor {
          display: flex;
          width: 50%;
          padding: 32px 16px;
          @media screen and (max-width: 768px) {
            width: calc(100% - 18px);
            padding: 12px 8px;
          }

          border-radius: 6px;
          cursor: pointer;

          &.selected {
            border: 2px solid lightseagreen;
            cursor: auto;
          }
          border: 1px solid darkgrey;

          .icon {
            width: 100px;
            margin-left: 8px;
            @media screen and (max-width: 768px) {
              width: 15%;
              margin: 0 12px 0 6px;
            }
            &.ii {
              background-image: url("../modals/img/institutional_investor_icon.jpg");
              background-size: contain;
              background-repeat: no-repeat;
            }
            &.ia {
              background-image: url("../modals/img/institutional_advisory_icon.jpg");
              background-size: contain;
              background-repeat: no-repeat;
            }
            &.tp {
              background-image: url("../modals/img/token_project_icon.jpg");
              background-size: contain;
              background-repeat: no-repeat;
            }
          }
          .text {
            width: 420px;
            @media screen and (max-width: 768px) {
              width: 80%;
            }
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 16px;
            h2 {
              color: #ffffff;
              margin: 0;
              font-family: "Encode Sans Bold";
              @include m-breakpoints;
            }
            span {
              @include s-breakpoints;
              font-family: "Poppins";
            }
          }
        }
      }

      .link {
        color: #ffffff;
        cursor: pointer;
      }
  
      .button-centerer {
        width: 100%;
        display: flex;
        justify-content: left;
        button {
          margin-top: 42px;
          height: 69px;
          @media screen and (max-width: 768px) {
            margin-top: 6.9px;
            height: 42px;
          }
          width: 169px;
          background: linear-gradient(to right, #2da1bc, #44da9d);
          color: #ffffff;
          font-weight: 600;
          border-radius: 8px;
          cursor: pointer;
          font-family: "Encode Sans Semi Bold";
          border: none;
        }
      }

      section {
        p {
          font-family: "Poppins";
          @include s-breakpoints;
        }
      }
    }

    &.contact {
      top: 0;
      height: calc(100vh - 242px);
      @media screen and (max-width: 768px) {
        height: calc(100vh - 42px);
      }
      overflow: scroll;

      &.styled-scrollbars {
        scrollbar-color: #999 #fff;
      }
      &.styled-scrollbars::-webkit-scrollbar {
        width: 10px;
        height: 0;
      }
      &.styled-scrollbars::-webkit-scrollbar-thumb {
        background: #fff;
        border-radius: 8px;
      }
      &.styled-scrollbars::-webkit-scrollbar-track {
        background: #0d2837;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
      }

      h1 {
        margin-bottom: 16px;
      }
      span {
        font-family: "Poppins";
        @include s-breakpoints;
      }
      form {
        display: flex;
        gap: 24px;
        flex-wrap: wrap;
        margin-top: 32px;

        .field {
          width: calc(50% - 24px);
          display: flex;
          flex-direction: column;
          label {
            margin-bottom: 16px;
            font-family: "Encode Sans Semi Bold";
            @include s-breakpoints;
            @media screen and (max-width: 768px) {
              height: 10px;
            }
          }
          input,
          select,
          .textbox {
            background: transparent;
            color: #ffffff;
            border: 1px solid #fff;
            border-radius: 8px;
            height: 72px;
            padding: 0 0 0 16px;
            @include s-breakpoints;
            @media screen and (max-width: 768px) {
              height: 60px;
            }
          }
          .textbox {
            height: 169px;
            padding: 16px;
          }
          select {
            option {
              background: #0d2837;
              color: #ffffff;
            }
          }
          textarea {
            font-family: "Poppins";
          }
          &.r {
            margin-left: 24px;
            @media screen and (max-width: 768px) {
              margin-left: 0;
            }
          }
          &.fullwidth {
            width: 100%;
          }
          &.mobfullwidth {
            @media screen and (max-width: 768px) {
              width: 100%;
              padding: 0;
            }
          }
          p {
            @include s-breakpoints;
          }
          .validation {
            color: red;
          }
        }

        .submit-button {
          width: 190px;
          height: 64px;
          background: linear-gradient(to right, #2da1bc, #44da9d);
          margin-top: 80px;
          color: #ffffff;
          border: 0;
          border-radius: 8px;
          cursor: pointer;
          @include s-breakpoints;
        }
      }
    }
  }

  .desktop-left {
    @media screen and (max-width: 768px) {
      display: none;
    }
    display: flex;
    width: 66%;
    padding-left: 69px;
    align-items: center;
    justify-content: flex-start;
    gap: 42px;
    color: #707070;
    @include xs-breakpoints;
    .orichal-logo {
      background: url("./img/orichal.jpg");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
      height: 69px;
      width: 169px;
      margin-right: calc(33% - 142px);
    }

    .link {
      font-family: "Encode Sans Semi Bold";
      text-decoration: none;
      color: #ffffff;

      &[data-selected="true"] {
        color: #48f0e1;
      }
    }
  }
  .mobile-left {
    display: none;
    @media screen and (max-width: 768px) {
      display: block;
    }
    width: 100%;
    height: 100%;
    .orichal-logo {
      background: url("./img/orichal.jpg");
      background-size: contain;
      background-repeat: no-repeat;
      height: calc(100% - 15px);
      width: calc(100% - 15px);
      margin: 7.5px 0 0 7.5px;
    }
  }
  .desktop-right {
    @media screen and (max-width: 768px) {
      display: none;
    }
    display: flex;
    align-items: center;
    padding-right: 69px;
    color: #ffffff;
    gap: 42px;
    font-family: "Encode Sans Semi Bold";
    @include xs-breakpoints;

    button {
      background: linear-gradient(to right, #2da1bc, #44da9d);
      margin-top: 5px;
      color: #ffffff;
      padding: 13px;
      border: 0;
      border-radius: 8px;
      cursor: pointer;
    }
  }
  .mobile-right {
    display: none;
    @media screen and (max-width: 768px) {
      display: flex;
    }
    .burger {
      display: flex;
      width: 30px;
      height: 30px;
      margin: 20px 15px 0 0;
      flex-direction: column;
      justify-content: space-between;
      .burger-bar {
        width: 100%;
        height: 3px;
        background: lightseagreen;
        transition: transform 0.3s ease-in;
      }

      &.ex {
        .burger-bar.top {
          transform: rotate(45deg) translate(9px, 10px);
        }
        .burger-bar.mid {
          transform: rotate(-45deg);
        }
        .burger-bar.bot {
          transform: rotate(45deg) translate(-9px, -9px);
        }
      }
    }
  }
  .mobile-modal-menu {
    position: fixed;
    top: 69px;
    left: 100%;
    width: 100%;
    height: 100vh;
    transition: left 0.3s ease-in;
    background: #0d2837;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &.open {
      left: 0;
    }

    .main-menu {
      display: flex;
      flex-direction: column;
      gap: 42px;
      @include m-breakpoints;
      color: #fff;
      font-family: "Encode Sans Thin";
      font-weight: 900;
      padding: 69px 42px;
      a {
        color: #fff;
      }
    }
    .buttons {
      display: flex;
      flex-direction: column;
      width: 100%;
      align-items: center;
      gap: 16px;
      button {
        width: calc(100% - 42px);
        background: linear-gradient(to right, #2da1bc, #44da9d);
        color: #ffffff;
        padding: 13px;
        border: 0;
        border-radius: 8px;
      }
    }
  }
}
