.TokenAdvisory-model{
  // background-color: #0d2837;
  .arco-modal-content{
    background-color: #0d2837;
  }
  .title{ 
    font-size: 24px;
    margin-bottom: 16px;
    line-height: 1.5;
    color: #ffffff;
    font-weight: 600;
  }
  .btn{
    display: flex;
    justify-content: flex-end;
    gap: 16px;
  }
}